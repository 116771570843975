import baseRest from '../base/rest';
import { getPrefix as _getPrefix } from './index';
// import store from "@/store";
import { request } from '../request';

const getPrefix = () => `${_getPrefix()}ad/`;

export default {
	...baseRest(getPrefix),  // create()

	getPrefix,


	/**
	 * Remove creative from campaign
	 *
	 * @param id - creative ID
	 * @param campaign_id - campaign ID
	 *
	 * @returns {promise} result
	 */
	async removeFromCampaign(id, campaign_id) {
		return await request(
			`${getPrefix()}${id}/remove-from-campaign/${campaign_id}/`,
			{},
			'delete'
		);
	},

	async removeDocFromAd(id, campaign_id, doc_id) {
		return await request(
			`${getPrefix()}${id}/campaign/${campaign_id}/remove/doc/${doc_id}/`,
			{},
			'delete'
		);
	},

	async addDoc(data, config) {
		return await request(
			`${getPrefix()}doc/`,
			data,
			'post',
			config
		);
	},
};