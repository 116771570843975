import { i18n } from '@/i18n.js';

export function getErrorCodeFromResponse(resp) {
	return resp?.data?.code;
}

export function convertError(resp, fields) { 
	const fieldErrors = convertFieldError(resp, fields);
	const backendErrors = convertBackendError(resp, fields);

	if (Object.keys(fieldErrors).length > 0) {
		return fieldErrors;
	}

	if (Object.keys(backendErrors).length > 0) {
		return backendErrors;
	}

	return false;
}

export function convertFieldError(resp, fields) {
	const data = resp.data;
	const errors = {};
	for (const key in data) {
		if (key == 'non_field_errors') {
			// show message from BE if 'non_field_errors'
			// because it's not imposible to convert message by key 'non_field_errors'
			errors[key] = data[key];
		} else if (Array.isArray(data[key]) && fields.includes(key)) {
			errors[key] = i18n.t(`errors.invalid_${key}`);
		}
	}
	return errors;
}

export function convertBackendError(resp, fields) {
	const data = resp.data;
	const errors = {};
	if (data.code && fields.includes(data.code)) {
		errors[data.code] = i18n.t(`errors.${data.code}`);
	}
	return errors;
}

export function getMessageFromRest(resp, default_message) {
	const data = resp.data;
	for (const key in data) {
		if (key == 'message') {
			return resp.data['message'];
		}
	}
	return default_message;
}