<template>
	<transition name="modal-transition">
		<nice-modal
			@close="cancel"
		>
			<section class="modal">
				<header class="header">
					<nice-icon-2
						class="header-icon"
						:icon="targetStatus.icon + '-34'"
					/>
					<div class="header-wrapper">
						<h2 class="title"><span class="action-name" :style="{ color: $palette[targetStatus.palette] }">{{ $t('campaign.creative_audit_action_noun_'+targetStatus.actionNounlabel) | capitalize}}</span> {{ $t('campaign.of') }} <span class="creative-name">{{ creative.name }}</span></h2>
					</div>
				</header>

				<div class="details cl-container cl-pad-no_sides">
					<div class="detail cl-xs12 cl-pad" >
						<nice-label class="detail-label" :label="$t('description')" />
						<span class="value multiline" v-html="creative.description || '&mdash;'" />
					</div>

					<!-- duration -->
					<div class="detail cl-xs4 cl-pad" >
						<nice-label class="detail-label" :label="$t('duration')" />
						<span class="value" v-html="formatDuration(creative[creative.type].duration) || '&mdash;'" />
					</div>

					<!-- uploading time -->
					<div class="detail cl-xs4 cl-pad" >
						<nice-label class="detail-label" :label="$t('campaign.uploaded')" />
						<span class="value" v-html="formatDate(creative.mtime) || '&mdash;'" />
					</div>

					<!-- author -->
					<div class="detail cl-xs4 cl-pad" >
						<nice-label class="detail-label" :label="$t('campaign.uploaded_by')" />
						<span class="value">{{ creative.created_by ? creative.created_by.username : '—' }}</span>
					</div>

					<template v-if="currentAudit">
						<hr class="cl-xs12 cl-pad"/>

						<div class="detail cl-xs4 cl-pad" >
							<nice-label class="detail-label" :label="$t('campaign.current_status')" />
							<span class="value" >
								<nice-icon-2 class="icon" :icon="status.icon" />
								<span class="action-name" :style="{ color: $palette[status.palette] }">{{ $t(status.label) | capitalize}}</span>
							</span>
						</div>

						<div class="detail cl-xs4 cl-pad" >
							<nice-label class="detail-label" :label="$t('campaign.changed')" />
							<span class="value" >{{ currentAudit.mtime ? formatDate(currentAudit.mtime) : '—' }}</span>
						</div>

						<div class="detail cl-xs4 cl-pad" >
							<nice-label class="detail-label" :label="$t('campaign.changed_by')" />
							<span class="value" >{{ currentAudit.modified_by ? currentAudit.modified_by.username : '—' }}</span>
						</div>

						<div v-if="currentAudit.feedback" class="detail cl-xs12 cl-pad" >
							<nice-label class="detail-label" :label="$t('campaign.audit_message')" />
							<span class="value multiline" >{{ currentFeedback || '—' }}</span>
						</div>
					</template>

					<hr class="cl-xs12 cl-pad"/>
					<div class="cl-xs12 cl-pad">
						<nice-label 
							class="feedback-label cl-xs4 cl-pad" 
							:label="$t('campaign.' + targetStatus.actionNounlabel + '_reason') | capitalize"
						/>
						<textarea
							v-model="feedback"
							class="feedback-textarea"
							rows="7"
							:placeholder="$t('campaign.feedback') | capitalize"
						></textarea>
					</div>
				</div>


				<footer class="footer">
					<nice-button-2
						:icon="targetStatus.icon + '-24'"
						:icon-placement="'start'"
						:palette="targetStatus.palette"
						@click="apply"
					>{{ $t('campaign.' + targetStatus.actionLabel) }}</nice-button-2>

					<nice-button-2
						palette="gray"
						@click="cancel"
					>{{ $t('cancel') }}</nice-button-2>
				</footer>
			</section>
		</nice-modal>
	</transition>
</template>

<script>
import moment from 'moment';

import NiceModal from '@/ui/nice-modal';
import { formatDuration } from '@/utilites';
import AUDIT_STATUSES from './audit-statuses';

function formatDate(date) {
	return moment(date).format('DD MMM`YY');
}

export default {
	name: 'CreativeAuditModal',

	components: {
		NiceModal,
	},

	props: {
		creative: {
			type: Object,
			required: true,
		},

		/**
		 * Specific ad.audits object to operate on
		 */
		currentAudit: {
			type: Object,
			required: true,
		},

		context: {
			type: Object,
			required: true,
		},


		// Target audit status code
		changeTo: {
			type: Number,
			required: true,
			validator(code) {
				return Boolean(AUDIT_STATUSES[code]);
			},
		},
	},

	data() {
		return {
			feedback: '',
		};
	},

	computed: {
		currentFeedback() {
			if (!(this.currentAudit && this.currentAudit.feedback && this.currentAudit.feedback.length)) {
				return null;
			}

			return this.currentAudit.feedback[this.currentAudit.feedback.length - 1];
		},

		status() {
			if (!this.currentAudit) {
				return null;
			}
			return AUDIT_STATUSES[this.currentAudit.status];
		},

		targetStatus() {
			return AUDIT_STATUSES[this.changeTo];
		},
	},

	methods: {
		formatDate,
		formatDuration,

		/**
		 * Apply changes and close the modal
		 */
		apply() {
			this.$emit('apply', {
				audit: this.currentAudit,
				status: this.changeTo,
				feedback: this.feedback,
			});
			this.close();
		},

		/**
		 * Cancel changes and close the modal
		 */
		cancel() {
			this.close();
		},

		/**
		 * Close the modal
		 */
		close() {
			this.$emit('close');
		},
	},
};
</script>



<style lang="sass" scoped>
.modal
	display: flex
	flex-direction: column
	padding: 30px 40px
	box-sizing: border-box
	flex-grow: 1
	overflow: auto
	min-height: 300px
	width: 1000px
	max-height: calc(100vh - 160px)

.header
	display: flex
	align-items: flex-start
	align-content: flex-start
	justify-content: flex-start
	margin-bottom: 20px

.header-icon
	width: 34px
	flex-shrink: 0

.header-wrapper
	&:not(:first-child)
	margin-left: 15px

.title
	font-size: 16px
	line-height: 20px
	margin: calc((34px - 20px) / 2) 0
	font-weight: normal

.creative-name
	color: var(--text_2_color, currentColor)

.details
	align-content: flex-start
	margin-bottom: 40px
	& > *
		// margin-bottom: 20px

.detail
	display: flex
	flex-direction: column

.value
	display: flex
	align-items: center
	line-height: 30px

	white-space: nowrap
	text-overflow: ellipsis

	&.multiline
		white-space: unset

	& > .icon
		margin-right: 10px

.footer
	display: flex
	margin-top: auto
	justify-content: flex-end

	& > :not(:last-child)
		margin-right: 35px



.modal-transition-enter-active,
.modal-transition-leave-active
	transition: opacity .2s

.modal-transition-enter,
.modal-transition-leave-to
	opacity: 0

.feedback-textarea
	width: 100%
	height: 50px
	min-height: 30px
	resize: vertical
	padding: 0.5em
	margin-top: 5px
	border: 1px solid #E9E9E9
	outline: none
	color: var(--text-color)

</style>
