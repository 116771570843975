<template>
	<section
		class="campaign-creative"
	>

		<p
			v-if="!stats.specs || !stats.specs.length"
			class="message message_no-device"
		>{{ $t('campaign.step04_no_devices') }}

		</p>


		<section
			v-for="format in creativeList"
			:key="`${format.type}-${format.resolution}`"
			:class="['creative-wrapper', { 'creative-wrapper-other': format.type == 'other' }]"
		>
			<!-- TODO: HACK: rename monitor -> screen here -->
			<h2 class="format-title">
				<!--TODO: update using types as translation keys
				{{ format.type === 'monitor' ? 'screen' : format.type | capitalize }}-->
				<template
					v-if="format.type === 'videoOrImage'"
				>
					<nice-icon-2
						class="format-title__icon"
						icon="resolution-14"
						:state="+format.resolution.split('x')[0] >= +format.resolution.split('x')[1] ? 0 : 90"
					/>

					{{ $t('campaign.monitor') | capitalize }}<!--
					-->{{ ', ' + format.resolution }}<!--
					-->{{ ', ' + (
							+format.resolution.split('x')[0] >= +format.resolution.split('x')[1]
							? $t('common.horizontal')
							: $t('common.vertical')
						)
					}}<!--
					--><span class="format-title__count">, {{ $tc('campaign.devices', format.count)}}</span>
				</template>

				<template
					v-else-if="format.type === 'audio'"
				>
					<nice-icon-2
						class="format-title__icon"
						icon="audio"
					/>
					{{ $t('campaign.audio') | capitalize }}<!--
					--><span class="format-title__count">, {{ $tc('campaign.devices', format.count)}}</span>
				</template>

				<template v-else >
					<nice-icon-2
						class="format-title__icon"
						icon="draft"
					/>
					{{ $t('campaign.other') | capitalize }}
				</template>
			</h2>


			<template v-if="format.ads && format.ads.length" >
				<div 
					v-for="(ad, index) in format.ads" :key="`${ad.id}`"
					class="creative-preview-wrapper"
				>
					<creative-preview
						v-if="ad.video !== null"
						:format="format"
						:creative="format.ads[index]"
						:context="context"
						class="creative-preview"
						auditable
						@remove="remove"
					/>

					<ul 
						v-if="ad.attachments && ad.attachments.length" 
						class="attachments-list"
					>
						<li 
							v-for="attachment in sortedAttachments(ad.attachments)" 
							:key="attachment.id"
						>
							<a :href="attachment.file" target="_blank">
								{{ attachment.file_name }}
							</a>
							<span 
								class="attachment-basket" 
								@click="removeAttachment(ad.id, attachment.id)" 
							>
								<nice-icon-2 icon="basket-14" />
							</span>
						</li>
					</ul>
					<template v-if="format.type != 'other'">
						<add-creative
							v-if="ad.video == null"
							:adId="ad.id"
							:context="context"
							:format="format"
							:disabled="isUploading(ad.id)"
							clean-on-upload

							@update:creativeData="pushCreative"
							@upload:start="setUploading(ad.id, true)"
							@upload:end="setUploading(ad.id, false)"
						/>
						<add-document 
							:adId="ad.id"
							:campaignId="context.instance.id"
							:group="`${format.type}-${format.resolution}`"
							:disabled="isUploading(ad.id)"

							@update:creativeData="pushCreative"
							@upload:start="setUploading(ad.id, true, false)"
							@upload:end="setUploading(ad.id, false, $event)"
						/>
						<nice-hint
							v-if="getUploadError(ad.id)"
							icon="error"
							class="upload_document__hint"
							:maxWidth="200"
						>{{ getUploadError(ad.id) }}</nice-hint>
					</template>
				</div>
			</template>

			<div v-else>
				<add-creative
					:context="context"
					:format="format"
					:disabled="isUploading(getGroup(format))"
					clean-on-upload

					@update:creativeData="pushCreative"
					@upload:start="setUploading(getGroup(format), true)"
					@upload:end="setUploading(getGroup(format), false)"
				/>
				<add-document 
					:campaignId="context.instance.id"
					:group="getGroup(format)"
					:disabled="isUploading(getGroup(format))"

					@update:creativeData="pushCreative"
					@upload:start="setUploading(getGroup(format), true, false)"
					@upload:end="setUploading(getGroup(format), false, $event)"
				/>
				<nice-hint
					v-if="getUploadError(getGroup(format))"
					icon="error"
					class="upload_document__hint"
					:maxWidth="200"
				>{{ getUploadError(getGroup(format)) }}</nice-hint>
			</div>

		</section>


		<div class="creative-specs">
			{{ $t('campaign.creative_text') }}
			<br />
			<br />
			<a
				v-if="$uioverrides.creative_specification_file"
				:href="$uioverrides.creative_specification_file"
				class="inline-link"
				target="_blank"
			>
				{{ $t('campaign.creative_full_spec_btn_label') }}
			</a>
			<a
				v-else
				class="inline-link"
				@click="isCreativeSpecShown = true"
			>
				{{ $t('campaign.creative_full_spec_btn_label') }}
			</a>
		</div>

		<div class="final-line">
			<span class="privacy-policies-line">
				{{ $t('campaign.privacy_policies_line_01') | capitalize }}
				<a
					v-if="$uioverrides.regulatory_docs_file"
					:href="$uioverrides.regulatory_docs_file"
					class="inline-link"
					target="_blank"
				>
					{{ $t('campaign.privacy_policies_line_02') }}
				</a>
				<a
					v-else
					class="inline-link"
					@click="isCreativePolicyShown = true"
				>
					{{ $t('campaign.privacy_policies_line_02') }}
				</a>
				{{ $t('campaign.privacy_policies_line_03') }}
			</span>

			<!-- draft button -->
			<nice-button-2
				watercolor
				class="next-button"
				@click="$emit('request-create', { doPlaceOrder: false, finish: true })"
			>
				{{ $t('campaign.save_as_draft_btn_label') | capitalize }}
			</nice-button-2>

			<span class="button-separator">{{ $t('campaign.or') }}</span>

			<!-- place order button -->
			<nice-button-2
				class="next-button"
				:disabled="isRequestNotValid"
				filled

				@click="$emit('request-create', { doPlaceOrder: true, finish: true })"
			>
				{{ $t('campaign.place_order_btn_label') | capitalize }}
			</nice-button-2>

			<nice-hint
				v-if="areAdsNotValid"
				icon="error"
				class="final-line__hint"
				:maxWidth="75"
			>{{ $t('campaign.no_creatives_issue') }}</nice-hint>
			<nice-hint
				v-else-if="!isEmailVerified"
				icon="error"
				class="final-line__hint"
				:maxWidth="75"
			>{{ $t('campaign.confirm_email') }}</nice-hint>
			<nice-hint
				v-else-if="isInsufficientBalance"
				icon="error"
				class="final-line__hint"
				:maxWidth="75"
			>{{ $t('campaign.insufficient_balance_request_dsp_view') }}</nice-hint>
		</div>


		<!-- modals -->
		<CreativeSpecification
			v-if="isCreativeSpecShown"
			@close="isCreativeSpecShown = false"
		/>
		<CreativePolicy
			v-if="isCreativePolicyShown"
			@close="isCreativePolicyShown = false"
		/>


	</section>
</template>


<script>
import Vue from 'vue';
import _ from 'underscore';
import { mapActions, mapGetters } from 'vuex';

import AddCreative from '@/components/creative/add-creative';
import AddDocument from '@/components/creative/add-document';
import CreativePreview from '@/components/creative/creative-preview';
import CreativeSpecification from '@/components/creative/creative-specification';
import CreativePolicy from '@/components/creative/policy';
import NiceHint from '@/ui/nice-hint';
import { getDevicesFilter, objectToQuery } from '@/utilites';


const MODEL = Object.freeze({
	prop: 'value',
	event: 'instance_change',
});

const CREATIVE_TYPE_BY_BROADCASTING_POINT_TYPE = Object.freeze({
	monitor: 'videoOrImage',
});


export default {
	name: 'Step04',


	components: {
		AddCreative,
		AddDocument,
		CreativePreview,
		NiceHint,
		CreativeSpecification,
		CreativePolicy,
	},


	model: MODEL,


	props: {
		stepCurrent: Number,

		stepCount: Number,

		// TODO: not needed here?
		goToNext: {
			type: Function,
			required: false,
		},

		goToPrev: {
			type: Function,
			required: true,
		},

		[MODEL.prop]: {
			type: Object,
			default: ()=>({}),
		},

		/*
		 * contains `instance`, `step`
		 */
		context: {
			type: Object,
			required: true,
		},
	},


	data() {
		return {
			instance: this[MODEL.prop],
			stats: {},

			isCreativeSpecShown: false,
			isCreativePolicyShown: false,
			uploadingStatus: {},
			uploadErrors: {}
		};
	},


	async mounted() {
		await this.updateStats();
	},


	computed: {
		...mapGetters('profile', [ 'isEmailVerified' ]),
		
		/**
		 * Show media arranged by format
		 *
		 * returns [
		 *	{
		 *		type: 'video',
		 *		resolution: '1920x1080',
		 *		ads: [],
		 *	},
		 *	{
		 *		type: 'audio',
		 *		ads: [],
		 *	},
		 *	...
		 * ]
		 */
		creativeList() {
			if (!this.stats || !this.stats.specs) {
				return [];
			}

			// clone specs, mixing in `ads[]` field, sorting by broadcasting points count
			const specs = this.stats.specs.map(spec => ({
				...spec,
				type: CREATIVE_TYPE_BY_BROADCASTING_POINT_TYPE[spec.type] || spec.type,
				ads: [],
			})).sort((aSpec, bSpec) => bSpec.count - aSpec.count);

			// safe clone creative list
			const requestAds = this.instance && this.instance.ads ? this.instance.ads.slice() : [];

			// drop if no ads
			if (!requestAds.length) {
				return specs;
			}

			// fill specs `ads[]` field with creatives
			const lostSpec = { type: 'other', ads: [] };

			while (requestAds.length) {
				const ad = requestAds.pop();
				let spec = specs.find(spec => {
					if (ad.video !== null) {
						return spec.type.toLowerCase().includes(ad.type) && ad.video.screen_resolution === spec.resolution;
					} else if (ad.group) {
						return ad.group.toLowerCase().includes(spec.resolution);
					}
				});

				if (!spec) {
					lostSpec.ads.push(ad);
					continue;
				}

				spec.ads.push(ad);
			}

			if (lostSpec.ads.length) {
				specs.push(lostSpec);
			}

			return specs;
		},


		/**
		 * device filter - request string
		 * @return {string} query string based on filter
		 */
		devicesFilter() {
			// we need only deployed devices here
			let filter = getDevicesFilter(this.context.instance, true);
			return objectToQuery(filter);
		},

		isInsufficientBalance() {
			return this.context?.instance?.owner_instance?.is_insufficient_balance;
		},

		areAdsNotValid() {
			return !this.instance.ads || !this.instance.ads.length
				|| this.instance.ads.every(ad => ad.video === null);
		},

		isRequestNotValid() {
			return !this.isEmailVerified
				|| this.isInsufficientBalance || this.areAdsNotValid;
		},
	},


	watch: {
		/**
		 * v-model
		 */
		[MODEL.prop]: {
			deep: true,
			async handler(newValue, oldValue) {
				if (_.isEqual(newValue, this.instance)) return;

				this.instance = newValue;
			}
		},

		/**
		 * v-model
		 */
		instance: {
			deep: true,
			async handler(newValue, oldValue) {
				if (_.isEqual(newValue, this[MODEL.prop])) return;

				this.$emit(MODEL.event, newValue);
			},
		},

		context: {
			async handler(newVal, oldVal) {
				await this.updateStats();
			},
		},
	},

	methods: {
		...mapActions('devices', [
			'getStats',
		]),

		...mapActions('creative', {
			getCreative: 'getItem',
			removeDocFromAd: 'removeDocFromAd',
			removeFromCampaign: 'removeFromCampaign',
		}),


		/**
		 * Get stats for the current selection
		 */
		async updateStats() {
			let res = await this.getStats({
				filter: this.devicesFilter,
			});

			this.$set(this, 'stats', res);
		},


		pushCreative(creative) {
			if (!creative) return null;

			// check if `instance` has ads
			if (!this.instance.ads) {
				this.$set(this.instance, 'ads', []);
			} else {
				// check if creative alerady in ads
				let ad = this.instance.ads.find(ad => ad.id === creative.id);
				if (ad) {
					this.instance.ads.splice(this.instance.ads.indexOf(ad), 1, creative);
					return null;
				}
			}

			this.instance.ads.unshift(creative);
		},


		/**
		 * Creative delete handler
		 */
		async remove(creative) {
			let res = await this.removeFromCampaign({
				id: creative.id,
				campaign: this.context.instance.id,
			});

			const targetId = res?.id || creative.id;
			this.updateOrRemoveAd(res, targetId);

			return res;
		},

		async removeAttachment(adId, docId) {
			let res = await this.removeDocFromAd({
				adId: adId,
				docId: docId,
				campaignId: this.context.instance.id,
			});

			const id = res?.id || adId;
			this.updateOrRemoveAd(res, id);

			return res;
		},

		updateOrRemoveAd(updatedAd, id) {
			const index = this.instance.ads.findIndex(ad => ad.id === id);

			if (index !== -1) {
				if (updatedAd) {
					Vue.set(this.instance.ads, index, updatedAd);
				} else {
					this.instance.ads.splice(index, 1);
				}
			}
		},

		sortedAttachments(attachments) {
			return attachments.slice().sort((a, b) => new Date(b.ctime) - new Date(a.ctime));
		},

		getGroup(format) {
			return `${format.type}-${format.resolution}`;
		},

		setUploading(id, isUploading, obj) {
			this.$set(this.uploadingStatus, id, isUploading);
			this.uploadErrors[id] = (obj?.error) ? obj.error : false;
		},

		isUploading(id) {
			return this.uploadingStatus[id] || false;
		},

		getUploadError(id) {
			return this.uploadErrors[id] || false;
		}
	}, // END: methods
};
</script>

<style lang="sass" scoped >
$width-input: 385px

.message
	font-size: 24px
	font-weight: 100

.message_no-device

.format-title
	// display: flex
	// align-items: center
	margin-bottom: 26px

.format-title__icon
	display: inline-block
	margin-right: 7px
	vertical-align: middle

.nice-icon__resolution-14
	&.state-90
		margin-top: -3px


.format-title__count
	color: var(--text_2_color)

.inline-button
	min-height: 0px

.privacy-policies-line
	line-height: 1.15
	text-align: right

.campaign-creative
	margin: $cmpnt-rc__content-margin-top auto 0
	max-width: $cmpnt-rc__content-width

.creative-wrapper
	display: flex
	flex-direction: column
	margin-bottom: 30px

.creative-preview
	// & + .creative-preview
	// 	margin-top: 15px

	&:nth-of-type(even)
		background: none


.nice-input
	margin: 0 auto 40px
	max-width: $width-input
	width: 100%

	&:last-child
		margin-bottom: 0

.next-button
	align-self: flex-end

.creative-specs
	margin-bottom: 80px
	font-size: $fsz__normal

	.nice-button
		padding-left: 0

.final-line
	margin-bottom: 80px
	display: flex
	position: relative
	justify-content: flex-end
	align-items: center

	> .privacy-policies-line
		font-size: $fsz__normal
		color: $nice_color-gray_dark
		margin-right: 30px

	> .button-separator
		margin: 0 10px

.inline-button
	padding-left: 0
	padding-right: 0

.final-line__hint
	margin-left: 10px

.inline-link
	color: var(--main_color)
	text-decoration: none
	text-transform: uppercase
	font-weight: bold
	font-size: smaller

	&:hover
		text-decoration: underline

.creative-wrapper-other .creative-preview-wrapper
    margin-bottom: 10px

.attachments-list
	margin-top: 0

.attachments-list li a
	color: var(--main_color)
	text-decoration: none
	margin-right: 1px

.attachments-list li a:hover,
.attachments-list li a:focus,
.attachments-list li a:active
	color: var(--main_color)

.attachment-basket
	position: relative
	top: 3px
	cursor: pointer
</style>
