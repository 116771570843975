<template>
	<section
		class="add-creative"
	>
		<creative-preview
			v-if="creative"
			:creative="creative"
			:format="actualFormat"
			:context="context"
			@remove="remove"
		/>

		<creative-uploader
			v-else-if="file"
			:adId="adId"
			:file="file"
			:format="actualFormat"
			:context="context"
			@cancel="onUploadingCancel"
			@uploaded="uploadedHandler"
			@upload:start="$emit('upload:start', $event)"
			@upload:end="$emit('upload:end', $event)"
		/>

		<div
			v-else
			class="upload-content-buttons"
		>
			<nice-file
				ref="fileInput"
				watercolor
				icon="load"
				icon-placement="start"
				icon-state="180"
				:accept="accept"
				:disabled="disabled"

				@change="fileChangeHandler"
			>{{$t('campaign.upload_content_btn_label')}}</nice-file>
		</div>
	</section>
</template>

<script>
import { mapActions } from 'vuex';

import CreativePreview from '@/components/creative/creative-preview';
import CreativeUploader from '@/components/creative/creative-uploader';
import NiceFile from '@/ui/nice-file.vue';


export const MODEL = Object.freeze({
	prop: 'creativeData',
	event: 'update:creativeData',
});


const MIME_TYPE_CHECK_REGEXP = /^(audio|image|video)\/.*/i;
const ACCEPT_FOR_CREATIVE_TYPE = Object.freeze({
	audio: 'audio/*',
	image: 'image/*',
	video: 'video/*',
	videoOrImage: 'video/*,image/*',
});
const DEFAULT_ACCEPT_ATTRIBUTE = 'audio/*,image/*,video/*';


export default {
	name: 'AddCreative',

	model: MODEL,

	components: {
		CreativeUploader,
		CreativePreview,
		NiceFile,
	},

	props: {
		format: {
			type: Object,
			required: true,
		},

		context: {
			type: Object,
			required: true,
		},

		adId: {
			type: Number,
			required: false,
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		[MODEL.prop]: Object,
		cleanOnUpload: Boolean,
	},

	data() {
		return {
			file: null,
			creative: this[MODEL.prop],
			// showAddCreativeModal: false,
			// uploadingContext: null,
		};
	},

	computed: {
		accept() {
			return ACCEPT_FOR_CREATIVE_TYPE[this.format.type] || DEFAULT_ACCEPT_ATTRIBUTE;
		},

		fileType() {
			if (!this.file || !this.file.type) {
				return 'unknown';
			}

			return this.file.type.split('/')[0];
		},

		/**
		 * We need to upload video or image via the same component
		 *
		 * This helps to get the actual media type.
		 * Needed for `CreativeUploader` and `CreativePreview`
		 */
		actualFormat() {
			return {
				...this.format,
				type: this.fileType,
			};
		},
	},

	methods: {
		...mapActions('creative', {
			getCreative: 'getItem',
			// deleteCreative: 'delete',
			// removeFromCampaign: 'removeFromCampaign',
		}),

		/**
		 * File change handler
		 * Sets the `file` inner data field
		 *
		 * @param {Event} event - event received from <input[type=file]>
		 */
		fileChangeHandler(files) {
			if (!files.length) {
				this.file = null;
				return null;
			}

			const file = files[0];

			// check mime-type && reject if it's not supported
			if (!MIME_TYPE_CHECK_REGEXP.test(file.type)) {
				this.$refs.fileInput.reset();
				this.$log.error(`File ${file.name} doesn't pass the mime-type check: '${file.type}' looks like non-media type. Expected 'audio/*' or 'image/*' or 'video/*'.`);
				throw new TypeError(`File ${file.name} doesn't pass the mime-type check: '${file.type}' looks like non-media type. Expected 'audio/*' or 'image/*' or 'video/*'.`);
			}

			this.$set(this, 'file', file);
		},


		onUploadingCancel() {
			this.$set(this, 'file', null);
		},

		/**
		 * Creative creation handler
		 * Gets creativeData from store,
		 * emits `update` event
		 *
		 * @param {object} creative - creative data received from creative-uploader
		 */
		async uploadedHandler(creativeData) {
			this.creative = creativeData;
			this.creative = await this.getCreative(creativeData.id);

			if (this.cleanOnUpload) {
				this.creative = null;
				this.file = null;
			}
		},

		/**
		 * Creative delete handler
		 * Erazes `creative` and `file` fields from inner data
		 * Emits `remove` event
		 */
		remove() {
			this.$emit('remove', this.creative);
			this.creative = null;
			this.file = null;
		},
	},

	watch: {
		/**
		 * Parent's creative data watcher
		 * @param {object} current - current parent's creative data
		 * @param {object} previous - previous parent's creative data
		 */
		[MODEL.prop](current, previous) {
			// if creatives are the same
			if (this[MODEL.prop] === this.creative) return null;

			// parent creative is empty
			if (!current) {
				this.creative = null;
				return null;
			}

			// no parent's creative or
			// inner and parent's creative have different ids
			if (!this.creative || this.creative.id !== current.id) {
				this.creative = current;
			}
		},


		/**
		 * Inner creative data watcher
		 * @param {object} current - current creative data
		 * @param {object} previous - previous creative data
		 */
		creative(current, previous) {
			// creative is provided by parent
			if (this[MODEL.prop] === current) {
				return null;
			}

			// creative is empty
			if (!current) {
				return this.remove();
			}

			// no parent's creative or
			// inner and parent's creative have different ids
			if (!this[MODEL.prop] || this[MODEL.prop].id !== current.id) {
				this.$emit(MODEL.event, this.creative);
			}
		},
	},
};
</script>


<style lang="sass" scoped>
.add-creative
	display: inline-block
	margin-right: 10px

.upload-content-buttons
	display: flex

	& > span
		display: inline-flex
		margin: 0 .8em
		padding: 6px 0px
</style>
