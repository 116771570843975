import AUDIT_STATUS_CODES from '@/constants/audit-status-codes';

import AUDIT_STATUSES from '../audit-statuses';


/**
 * Generate actions list according to the specified status
 *
 * @return {[type]} [description]
 */
export const mixin = {
	methods: {
		auditActionList(audit) {

			const collectListItem = statusCode => {
				const status = AUDIT_STATUSES[statusCode];
				return {
					value: statusCode,
					icon: status.icon,
					label: this.$t('campaign.creative_audit_status_' + status.label_translation_id),
					labelAction: this.$t('campaign.creative_audit_action_' + status.action_label_tr_id),
				};
			};

			const current = audit ? audit.status : AUDIT_STATUS_CODES.PENDING_AUDIT;
			const actionList = [ collectListItem(current) ];


			switch (current) {
			case AUDIT_STATUS_CODES.PENDING_AUDIT:
			case AUDIT_STATUS_CODES.CHANGED_RESUBMISSION_REQUESTED:
				actionList.push(collectListItem(AUDIT_STATUS_CODES.PRE_APPROVED));
				actionList.push(collectListItem(AUDIT_STATUS_CODES.APPROVED));
				actionList.push(collectListItem(AUDIT_STATUS_CODES.DENIED));
				break;

			case AUDIT_STATUS_CODES.PRE_APPROVED:
				actionList.push(collectListItem(AUDIT_STATUS_CODES.APPROVED));
				actionList.push(collectListItem(AUDIT_STATUS_CODES.DENIED));
				break;

			case AUDIT_STATUS_CODES.APPROVED:
				actionList.push(collectListItem(AUDIT_STATUS_CODES.DENIED));
				break;

			case AUDIT_STATUS_CODES.DENIED:
				actionList.push(collectListItem(AUDIT_STATUS_CODES.PRE_APPROVED));
				actionList.push(collectListItem(AUDIT_STATUS_CODES.APPROVED));
				break;

			case AUDIT_STATUS_CODES.EXPIRED:
				actionList.push(collectListItem(AUDIT_STATUS_CODES.PENDING_AUDIT));
				break;

			default:
				return null;
			}

			return actionList;
		},
	}
};
