<template>
	<section class="add-document">
		<nice-file 
			ref="fileInput" 
			watercolor icon="load" 
			icon-placement="start" 
			icon-state="180" 
			:accept="accept"
			:multiple="true" 
			:disabled="disabled"

			@change="filesChangeHandler"
		>
			{{ $t('campaign.upload_doc_btn_label') }}
		</nice-file>
	</section>
</template>

<script>

import { mapActions } from 'vuex';
import NiceFile from '@/ui/nice-file.vue';
import { getMessageFromRest } from '@/ui/utils/errors';

export const MODEL = Object.freeze({
	event: 'update:creativeData',
});

const UPLOAD_TIMEOUT = 300 * 1000; // 5 minutes
const DOCUMENT_MIME_TYPE_CHECK_REGEXP = /^(image\/.*|application\/pdf)$/i;
const DEFAULT_ACCEPT_ATTRIBUTE_FOR_DOCUMENT_TYPE = 'image/*,application/pdf';

export default {
	name: 'AddDocument',

	components: {
		NiceFile,
	},

	props: {
		campaignId: {
			type: Number,
			required: true,
		},
		group: {
			type: String,
			required: true,
		},
		adId: {
			type: Number,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			files: [],
		};
	},

	computed: {
		accept() {
			return DEFAULT_ACCEPT_ATTRIBUTE_FOR_DOCUMENT_TYPE;
		},
	},

	methods: {

		...mapActions('creative', {
			addDoc: 'addDoc',
		}),

		filesChangeHandler(files) {
			if (!files || files.length === 0) {
				this.files = [];
				return;
			}

			const validFiles = Array.from(files).filter(file => {
				if (!DOCUMENT_MIME_TYPE_CHECK_REGEXP.test(file.type)) {
					this.$log.error(`File ${file.name} doesn't pass the mime-type check: '${file.type}' looks like non-media type. Expected 'image/*' or 'application/pdf'.`);
					return false;
				}
				return true;
			});

			if (validFiles.length === 0) {
				this.$refs.fileInput.reset();
				throw new TypeError('No valid files were selected. Expected media type: `image/*` or `application/pdf`');
			}

			this.uploadSelectedFiles(validFiles);
		},

		uploadSelectedFiles(files) {
			this.$emit('upload:start');

			let data = {
				type: 'doc',
				docs: files,
				id: this.adId,
				campaign_id: this.campaignId,
				group: this.group,
				screen_resolution: '0x0',
			};

			const config = {
				headers: { 'Content-Type': 'multipart/form-data' },
				timeout: UPLOAD_TIMEOUT,
			};

			this.addDoc({ data, config })
				.then(creative => {
					this.$emit(MODEL.event, creative);
					this.$emit('upload:end', { success: true });
				})
				.catch(error => {
					this.$log.error(error);
					let message = getMessageFromRest(error.response, this.$t('errors.failed_to_upload_docs'));
					this.$emit('upload:end', { success: false, error: message });
				})
				.finally(() => {
					if (this.$refs.fileInput) {
						this.$refs.fileInput.reset();
					}
				});
		},
	},
};
</script>

<style lang="sass" scoped>
.add-document
  display: inline-block
  margin-right: 10px
</style>
