<template>
	<nice-button-2
		:disabled="disabled"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template
			v-if="selection"
		>
			<span class="nice-file__filename">{{ selectionLabel }}</span>, {{ formatFileSize(selectionSize) }}
		</template>
		<slot v-else ><!-- provided caption --></slot>

		<input
			ref="input"
			type="file"
			:name="name"
			:accept="accept"
			:multiple="multiple"
			:disabled="disabled"
			class="nice-file__input"

			@change.stop="fileChangeHandler"
		/>
	</nice-button-2>
</template>

<script>
import { formatFileSize } from '@/utilites';
import NiceButton2 from '@/ui/nice-button-2';


export default {
	name: 'NiceFile',

	components: {
		NiceButton2,
	},

	props: {
		name: {
			type: String,
			default: 'file'
		},

		accept: {
			type: String,
			default: '*/*',
		},

		disabled: {
			type: Boolean,
			default: false,
		},

		multiple: Boolean,
	},

	data() {
		return {
			selection: null,
		};
	},

	computed: {
		selectionLabel() {
			if (!this.selection || !this.selection.length) {
				return '';
			}

			if (this.selection.length > 1) {
				return this.$t('common.n_files', this.selection.length);
			}

			return Array.prototype.map.call(this.selection, file => file.name).join(', ');
		},


		selectionSize() {
			if (!this.selection || !this.selection.length) {
				return null;
			}

			return Array.prototype.reduce.call(this.selection, (total, file) => total + file.size, 0);
		},
	},


	methods: {
		formatFileSize,

		reset() {
			this.$refs.input.value = '';
			this.$set(this, 'selection', null);
			this.update();
		},

		update() {
			this.$emit('change', this.selection);
		},

		fileChangeHandler(event) {
			if (this.disabled) {
				return;
			}
		
			const input = event.target;

			if (!(input && input.files)) {
				this.reset();
				return;
			}

			this.$set(this, 'selection', [...input.files]);
			this.update();
		},
	},
};
</script>


<style lang="sass" scoped >
.nice-file__input
	position: absolute
	display: block
	top: 0
	left: 0
	width: 100%
	height: 100%
	margin: 0
	padding: 0
	opacity: 0
	cursor: pointer
	z-index: 1

.nice-file__filename
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
	flex-shrink: 1
</style>
