<template>
	<div class="creative-uploader">
		<div class="preview">
			<!-- video-preview -->
			<video
				v-if="type === 'video' && mediaUrl"
				ref="media"
				class="video-preview"
				:src="mediaUrl"
				:type="mediaData.mimeType"
				playsinline
				muted
				preload="metadata"

				@loadedmetadata="onMediaMetadataLoaded"
				@doubleclick="isDetailsModalShowing = true"
			/>

			<img
				v-if="type === 'image' && mediaUrl"
				ref="media"
				class="video-preview"
				:src="mediaUrl"

				@load="onMediaMetadataLoaded"
			/>

			<template
				v-if="type === 'audio'"
			>
				<audio
					v-if="mediaUrl"
					ref="media"
					class="video-preview"
					:src="mediaUrl"
					:type="mediaData.mimeType"
					playsinline
					muted
					preload="metadata"

					@loadedmetadata="onMediaMetadataLoaded"
					@doubleclick="isDetailsModalShowing = true"
				/>
				<nice-icon-2
					icon="melody-15"
					class="preview-icon"
				/>
			</template>

			<!-- progress-bar -->
			<nice-progress-bar
				v-if="status === 'uploading' || status === 'uploaded'"
				class="progress-bar"
				:value="uploadingContext.loaded || 0"
				:max="uploadingContext.total"
				hide-value
			/>
		</div>

		<header class="top">
			<h3 class="title">

				<button
					v-if="status === 'waiting' || status === 'canceled' || status === 'failed'"
					class="title__edit-button"
					@click="isDetailsModalShowing = true"
				>{{ name }} <nice-icon-2 icon="pencil-13" class="title__icon" /></button>
				<template v-else >{{ name }}</template>
			</h3>

			<div v-if="creativeStatus" class="status">
				<nice-icon-2
					:icon="creativeStatus.icon"
					:state="creativeStatus.iconState"
				/>
				<span v-html="$t('campaign.creative_status_' + creativeStatus.label)" />
			</div>
		</header>


		<div class="bottom">
			<div class="details">

				<!-- resolution -->
				<div
					v-if="type === 'video' || type === 'image'"
					class="detail"
				>
					<nice-label
						:label="$t('campaign.resolution')"
						:error="resolutionIssue"
						class="detail-label"
					/>
					<span class="value" v-html="creativeResolution" />
				</div>

				<!-- duration -->
				<div
					v-if="type === 'video' || type === 'audio'"
					class="detail"
				>
					<nice-label
						:label="$t('campaign.duration')"
						:error="durationIssue"
						class="detail-label"
					/>
					<span class="value" v-html="formatDuration(creativeData[type].duration)" />
				</div>

				<!-- ad duration (for `image`) -->
				<div
					v-if="type === 'image'"
					class="detail"
				>
					<nice-label
						:label="$t('campaign.duration')"
						class="detail-label"
					/>
					<span class="value" v-html="formatDuration(context.instance.ad_duration) || '&mdash;'" />
				</div>


				<!-- file-size -->
				<div class="detail" >
					<nice-label class="detail-label" :label="$t('campaign.file_size')" />
					<span class="value" v-html="formatFileSize(file.size)" />
				</div>
			</div>

			<!-- actions -->
			<div class="actions">

					<!-- delete -->
					<nice-button-2
						v-if="status === 'uploaded'"
						icon-painted
						icon-placement="start"
						icon="basket-24"
						palette="gray"

						@click="cancelCreativeCreation"
					>{{ $t('campaign.delete') }}</nice-button-2>

					<!-- cancel -->
					<nice-button-2
						v-if="status === 'waiting' || status === 'canceled' || status === 'failed'"
						icon-painted
						icon-placement="start"
						icon="cancel-24"
						palette="gray"

						@click="cancelCreativeCreation"
					>{{ $t('campaign.cancel') }}</nice-button-2>

					<!-- edit details -->
					<!-- <nice-button-2
						v-if="status === 'waiting' || status === 'canceled' || status === 'failed'"
						icon-painted
						icon-placement="start"
						icon="pencil-24"
						palette="gray"

						@click="isDetailsModalShowing = true"
					>{{ $t('campaign.edit') }}</nice-button-2> -->

					<!-- details -->
					<nice-button-2
						v-if="status === 'uploading' || status === 'uploaded'"
						icon-painted
						icon-placement="start"
						icon="see-24"
						palette="gray"

						@click="isDetailsModalShowing = true"
					>{{ $t('campaign.details') }}</nice-button-2>

					<!-- upload -->
					<nice-button-2
						v-if="status === 'waiting' || status === 'canceled' || status === 'failed'"
						icon-painted
						icon-placement="start"
						icon-state="180"
						icon="load-24"
						palette="gray"

						@click="upload"
					>{{ $t('campaign.upload') }}</nice-button-2>

					<!-- cancel uploading -->
					<nice-button-2
						v-if="status === 'uploading'"
						icon-painted
						icon-placement="start"
						icon="cancel-24"
						palette="gray"

						@click="cancelUploading"
					>{{ $t('campaign.cancel_uploading') }}</nice-button-2>

			</div>
		</div>

		<transition name="modal-transition">
			<creative-details-modal
				v-if="isDetailsModalShowing"
				:context="modalContext"
				:creative="creativeData"
				:edit-mode="detailsIsEditable"
				:icon="detailsIsEditable ? 'pencil-34' : 'see-34'"
				:title="detailsIsEditable ? $t('campaign.edit_details') : $t('campaign.details')"

				@apply="modalApplyHandler"
				@cancel="modalCancelHandler"
				@close="closeModal"
			/>
		</transition>
	</div>
</template>


<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import {
	profileFormatDate as formatDate,
} from '@/utilites';

import CreativeDetailsModal from '@/components/creative/creative-details-modal';
import NiceProgressBar from '@/ui/nice-progress-bar';
import { formatDuration, formatFileSize } from '@/utilites';

const CancelToken = axios.CancelToken;


const UPLOAD_TIMEOUT = 300 * 1000; // 5 minutes


const CREATIVE_STATUSES = {
	waiting: {
		icon: 'draft',
		label: 'waiting',
	},

	canceled: {
		icon: 'draft',
		label: 'canceled',
	},

	uploading: {
		icon: 'arrow_circle_2',
		iconState: 180,
		label: 'uploading',
	},

	uploaded: {
		icon: 'check',
		label: 'uploaded',
	},

	failed: {
		icon: 'error',
		label: 'failed',
	},
};


export default {
	name: 'CreativeUploader',

	components: {
		CreativeDetailsModal,
		NiceProgressBar,
	},

	props: {
		file: {
			type: File,
			required: true,
		},

		format: {
			type: Object,
			required: true,
		},

		context: {
			type: Object,
			required: true,
		},

		adId: {
			type: Number,
			required: false,
		}
	},

	data() {
		return {
			name: this.composeCreativeName() || '',
			description: '',
			mediaData: {
				name: this.file.name.replace(/\.[^.]*$/, ''),
				mimeType: this.file.type,
				duration: null,
				width: null,
				height: null,
			},
			isDetailsModalShowing: true,
			initialized: false,
			status: 'waiting',

			uploadingContext: {
				total: null,
				loaded: null,
				axiosCancelToken: null,
				cancel: null,
			},

			mediaUrl: null,
		};
	},

	computed: {
		/**
		 * Switch status description by status
		 */
		creativeStatus() {
			return CREATIVE_STATUSES[this.status];
		},

		/**
		 * Collected creative data
		 */
		creativeData() {

			const media = {
				file: this.file,
				mime_type: this.file.type,
				file_size: this.file.size,
				duration: Math.round((this.mediaData.duration)*100) / 100,
			};

			const data = {
				name: this.name,
				description: this.description,
				type: this.type,
				[this.type]: media,
			};

			switch (this.type) {
			case 'audio':
				break;

			case 'image':
				media.resolution = `${this.mediaData.width}x${this.mediaData.height}`;
				media.screen_resolution = this.format.resolution;
				break;

			case 'video':
				media.resolution = `${this.mediaData.width}x${this.mediaData.height}`;
				media.screen_resolution = this.format.resolution;
				break;

			default:
				this.$log.warn(`Creative type '${this.type}'. Update supported types.`);
				throw new TypeError(`Creative type '${this.type}' not recognized.`);
			}

			return data;
		},


		/**
		 * For `video` and `image` types
		 */
		creativeResolution() {
			const creative = this.creativeData[this.type];
			return creative.resolution;
		},


		/**
		 * For `video` and `image` types
		 */
		creativeScreenResolution() {
			const creative = this.creativeData[this.type];
			return creative.screen_resolution;
		},


		/**
		 * Ediatable details flag
		 */
		detailsIsEditable() {
			return [
				'waiting',
				'canceled',
				'failed',
			].includes(this.status);
		},


		/**
		 * Extra data for a details modal
		 * @return {object} modal context
		 */
		modalContext() {
			const data = {
				campaign_mame: this.context.instance.name,
				campaign_id: this.context.instance.id,
				format: this.format,
				instance: this.context.instance,
			};

			if (this.context.instance.advertiser) {
				data.company_name = this.context.instance.advertiser.name;
			}

			return data;
		},

		/**
		 * @return {string} file media type
		 */
		type() {
			// if (this.file && this.file.type) return this.file.type.split('/')[0];
			if (this.format) {
				return this.format.type;
			}

			return null;
		},

		// resolution mismatch issue
		resolutionIssue() {
			if (!(this.format && (this.format.type === 'video' || this.format.type === 'image'))) {
				return false;
			}

			if (this.creativeScreenResolution === this.creativeResolution) {
				return false;
			}

			return this.$t('errors.creative_reslution_mismatch', {
				current: this.creativeResolution,
				target: this.creativeScreenResolution,
			});
		},

		// duration missmstch issue
		durationIssue() {
			if (this.context.instance.ad_duration === Math.round(this.creativeData[this.type].duration)) {
				return false;
			}

			return this.$t('errors.creative_duration_mismatch', {
				current: this.formatDuration(this.creativeData[this.type].duration),
				target: this.formatDuration(this.context.instance.ad_duration),
			});
		},
	},


	methods: {
		...mapActions('creative', {
			createCreative: 'create',
		}),

		formatDuration,
		formatFileSize,


		/**
		 * Gets some data about the video
		 *
		 * @param {Event} event
		 */
		onMediaMetadataLoaded(event) {

			this.mediaData.name = this.file.name.replace(/\.[^.]*$/, '');

			if (this.type === 'audio') {
				this.mediaData.duration = this.$refs.media.duration;
			}

			if (this.type === 'video') {
				this.mediaData.duration = this.$refs.media.duration;
				this.mediaData.width = this.$refs.media.videoWidth;
				this.mediaData.height = this.$refs.media.videoHeight;
				this.uploadingContext.video = this.mediaUrl;
			}

			if (this.type === 'image') {
				this.mediaData.width = this.$refs.media.naturalWidth;
				this.mediaData.height = this.$refs.media.naturalHeight;
				this.uploadingContext.video = this.mediaUrl;
			}
		},

		/**
		 * Updates name and description of the creative
		 * Starts uploading if `this.initialized==false`
		 *
		 * @param {string} options.name - creative name
		 * @param {string} options.description - creative description
		 */
		modalApplyHandler({ name, description }) {
			this.name = name;
			this.description = description;
			this.closeModal();

			// autoupload if there are no file issues
			// and the modal was opened automatically on file select
			if (!this.initialized) {
				this.upload();
			}

			this.initialized = true;
		},

		/**
		 * Closes the details modal
		 */
		closeModal() {
			this.isDetailsModalShowing = false;
		},

		/**
		 * If it's not `initialized` cancels whole uploading
		 * else just closes modal
		 */
		modalCancelHandler() {
			if (!this.initialized) {
				this.status = 'canceled';
				this.uploadingContext.loaded = null;
				this.uploadingContext.total = null;
				this.$emit('cancel');
				return null;
			}

			this.closeModal();
		},

		/**
		 * Upload creative
		 */
		upload() {
			this.$emit('upload:start');

			// prepair data to transfer
			const media = this.creativeData[this.type];
			let data = {
				id: this.adId,
				group: `${this.format.type}-${this.format.resolution}`,
				...this.creativeData,
				// api requires flat structure
				...media,
				// pass campaign id with ad
				campaign_id: this.context.instance.id,
				// pass campaign duration for image ads
				ad_duration: this.context.instance.ad_duration,
			};

			// pass advertiser if set
			if (this.context.instance.advertiser) {
				data.advertiser = this.context.instance.advertiser;
			}

			delete data[this.type];

			this.status = 'uploading';

			const config = {
				// set content-type for converter at '@/api/request'
				headers: { 'Content-Type': 'multipart/form-data' },
				cancelToken: this.uploadingContext.cancelToken,
				onUploadProgress: (event) => {
					this.uploadingProgressHandler(event);
				},
				timeout: UPLOAD_TIMEOUT,
			};

			this.createCreative({ data, config })
				.then(data => {
					this.onCreate(data);
					this.$emit('upload:end', { success: true });
				})
				.catch(error => {
					this.$log.error(error);
					this.status = 'failed';
					this.uploadingContext.loaded = null;
					this.uploadingContext.total = null;
					this.$emit('upload:end', { success: false });
				});
		},

		/**
		 * Break uploading process
		 */
		cancelUploading() {
			if (this.status !== 'uploading') return null;

			const cancel = this.uploadingContext.cancel;

			if (typeof cancel === 'function') {
				cancel();
			} else {
				throw new TypeError('Creative uploader error:\nSomething gone wrong with the axios cancelToken.');
			}

			this.status = 'canceled';
			this.uploadingContext.loaded = null;
			this.uploadingContext.total = null;
		},

		/**
		 * Cancel the creative creation
		 */
		cancelCreativeCreation() {
			this.$emit('cancel');
		},


		/**
		 * Creative creation handler
		 * Changes status, emits finishing event, provides the creative up
		 * @param {object} creative - creative data, received on uploading finish
		 */
		onCreate(creative) {
			this.$emit('uploaded', creative);
			this.status = 'uploaded';
		},

		/**
		 * Uploading progress handler
		 * Updates `uploadingContext` progress and total properties
		 *
		 * @param {object} progressEvent - progress event that instance received by axios
		 *
		 */
		uploadingProgressHandler(progressEvent) {
			this.uploadingContext.total = progressEvent.total;
			this.uploadingContext.loaded = progressEvent.loaded;
		},


		// creative name generator
		composeCreativeName() {
			const company = this.context.instance.advertiser_instance
				? this.context.instance.advertiser_instance.name
				: null;

			let name = formatDate(new Date());

			name += `_${this.type}`;

			if (this.type === 'video' || this.type === 'image') {
				name += `_${this.creativeScreenResolution}`;
			}


			if (company) {
				name += `_for_${company}`;
			}

			return name;
		},
	},

	created() {
		// axios stuff

		this.name = this.composeCreativeName() || '',
		this.uploadingContext.axiosCancelToken = new CancelToken(cancel => {
			this.uploadingContext.cancel = cancel;
		});
	},


	mounted() {
		this.mediaUrl = window.URL.createObjectURL(this.file);
	},
};
</script>


<style lang="sass" scoped>
.creative-uploader
	display: grid
	grid-template-columns: 160px auto
	grid-template-areas: "preview top" "preview bottom"

	background: $nice_color-gray_light_ultra-2
	border-radius: 10px
	min-height: 125px
	padding: 20px
	column-gap: 30px
	row-gap: 35px

	box-sizing: border-box
	min-height: 125px

.preview
	position: relative
	z-index: 0
	grid-area: preview
	align-self: stretch
	justify-self: stretch
	overflow: hidden
	border-radius: 10px

	min-height: 90px

	display: flex
	align-items: center
	justify-content: center
	background: $nice_color-gray

.preview-icon
	display: block
	position: absolute
	height: 60px
	width: 100%
	opacity: .3
	top: 50%
	left: 0%
	transform: translate3d(0, -50%, 0)
	--ni-icon-sign: currentColor

.progress-bar
	position: absolute
	z-index: 1
	top: 0
	right: 0
	bottom: 0
	left: 0

	&::v-deep > .ni_progress_bar--bar
		height: 100%

		&::before
			top: 0
			height: 100%

		&::after
			top: 0
			height: 100%
			opacity: .7

.video-preview
	position: absolute
	display: block
	height: 100%
	width: 100%
	top: 0
	right: 0
	bottom: 0
	left: 0
	object-fit: cover

.top,
.bottom
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center

.top
	grid-area: top
	align-self: start
	padding-top: 5px

.bottom
	grid-area: bottom
	align-self: end
	// padding-bottom: 5px



.title
	font-size: $fsz__normal
	font-weight: normal
	margin: 0

.title__edit-button
	appearance: none
	dispaly: inline
	margin: 0
	padding: 0
	border: none
	background: none
	font-size: inherit
	color: inherit
	line-height: inherit
	font-family: inherit

	&:not(:disabled)
		cursor: pointer

.title__icon
	margin-left: 4px
	vertical-align: middle



.status
	display: flex
	color: $nice_color-darkgray
	font-size: $fsz__smaddle
	align-items: center
	margin-left: auto

	& > :not(:first-child)
		margin-left: 10px

.details
	grid-area: details
	align-self: end

	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center


.detail-label
	margin-top: -8px

.detail
	display: flex
	flex-direction: column

	&:not(:first-child)
		margin-left: 35px

	& > .value
		line-height: 30px

.value.placeholder
	color: var(--text_2_color)

.actions
	display: flex
	align-items: center
	margin-left: auto

	& > :not(:first-child)
		margin-left: 30px

.modal-transition-enter-active,
.modal-transition-leave-active
	transition: opacity .2s

.modal-transition-enter,
.modal-transition-leave-to
	opacity: 0



</style>
